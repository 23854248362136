import {
  DrawerButton,
  FilterButton,
  SearchBar,
  SingleSelect,
  Table,
} from '@campxdev/shared'
import { Button, Typography } from '@mui/material'
import { fetchTransportRegistration } from 'pages/Registrations/services'
import { batchOptions } from 'pages/constants'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { feePaymentsExport } from './ExportToExcel'
import ReportFilters from './ReportFilters'
import { StyledBox, StyledBoxInner } from './Styles'

export const defaultObj = {
  filters: {
    transportBatch: batchOptions[0],
    limit: 10,
    skip: 0,
    search: '',
    institutionId: null,
  },
  filtersApplied: false,
}
export const FeePaymentsReport = () => {
  const [state, setState] = useState(defaultObj)
  const { data, isLoading } = useQuery(
    ['feePaymentsReport', state.filters],
    () => fetchTransportRegistration(state.filters),
  )

  const handlePagination = (value) => {
    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        skip: (value - 1) * prev.filters.limit,
      },
    }))
  }

  const handlePageLimit = (value: number) => {
    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        limit: value,
        skip: 0,
      },
    }))
  }

  const columns = [
    {
      title: 'Admission ID',
      dataIndex: 'admissionId',
      key: 'admissionId',
    },
    {
      title: 'Name',
      dataIndex: 'admissionSubValue.fullName',
      key: 'fullName',
      render: (_, row) => {
        return (
          <Typography>
            {row?.feeDue?.admissionSubValue?.fullName
              ? row?.feeDue?.admissionSubValue?.fullName
              : '-'}
          </Typography>
        )
      },
    },
    {
      title: 'Roll No',
      dataIndex: 'feeDue.admissionSubValue.rollNo',
      key: 'rollNumber',
      render: (_, row) => {
        return (
          <Typography>
            {row?.feeDue?.admissionSubValue?.rollNo
              ? row?.feeDue?.admissionSubValue?.rollNo
              : '-'}
          </Typography>
        )
      },
    },
    {
      title: 'Batch',
      dataIndex: 'feeDue.admissionSubValue.batch',
      key: 'batch',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Route Name',
      dataIndex: 'route.routeName',
      key: 'routeName',
    },
    {
      title: 'Total Fee',
      dataIndex: 'feeDue.payableAmount',
      key: 'payableAmount',
    },
    {
      title: 'Total Paid',
      dataIndex: 'feeDue.debit',
      key: 'debit',
    },
    {
      title: 'Total Due',
      dataIndex: 'feeDue.totalDue',
      key: 'balance',
    },
  ]

  return (
    <>
      <StyledBox>
        <StyledBoxInner>
          <SingleSelect
            label="Transpsort Batch"
            value={state.filters.transportBatch}
            options={batchOptions.map((item) => ({
              label: item,
              value: item,
            }))}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                filters: {
                  ...prev.filters,
                  transportBatch: e.target.value,
                },
              }))
            }
          />
          <SearchBar
            onSearch={(value) => {
              setState((prev) => ({
                ...prev,
                filters: {
                  ...prev.filters,
                  search: value,
                },
              }))
            }}
          />
          <DrawerButton
            anchor={({ open }) => (
              <FilterButton
                onClick={open}
                filtersApplied={state.filtersApplied}
              />
            )}
            content={({ close }) => (
              <ReportFilters close={close} setState={setState} state={state} />
            )}
            title="Apply Filters"
          />
        </StyledBoxInner>
        <Button
          variant="outlined"
          onClick={() => feePaymentsExport(state.filters)}
        >
          Export
        </Button>
      </StyledBox>

      <Table
        key={'feePaymentsReport'}
        columns={columns}
        dataSource={data?.registrations ?? []}
        loading={isLoading}
        pagination={{
          page: state.filters.skip / state.filters.limit,
          limit: state.filters.limit,
          onChange: handlePagination,
          onChangeLimit: handlePageLimit,
          totalCount: data?.count,
        }}
      />
    </>
  )
}

export default FeePaymentsReport
