import {
  DrawerButton,
  FilterButton,
  SearchBar,
  SingleSelect,
  Table,
} from '@campxdev/shared'
import { Button, Typography } from '@mui/material'
import { batchOptions } from 'pages/constants'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchFeeDuesReport } from '../service'
import { feeDuesExport } from './ExportToExcel'
import ReportFilters from './ReportFilters'
import { StyledBox, StyledBoxInner } from './Styles'

export const defaultObj = {
  filters: {
    limit: 10,
    skip: 0,
    search: '',
    transportBatch: batchOptions[0],
    institutionId: null,
  },
  filtersApplied: false,
}

const FeeDuesReport = () => {
  const [state, setState] = useState(defaultObj)
  const { data, isLoading } = useQuery(['feeDuesReport', state.filters], () =>
    fetchFeeDuesReport(state.filters),
  )
  const handlePagination = (value) => {
    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        skip: (value - 1) * prev.filters.limit,
      },
    }))
  }

  const handlePageLimit = (value: number) => {
    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        limit: value,
        skip: 0,
      },
    }))
  }

  const columns = [
    {
      title: 'Admission ID',
      dataIndex: 'admissionId',
      key: 'admissionId',
    },
    {
      title: 'Name',
      dataIndex: 'admissionSubValue.fullName',
      key: 'fullName',
      render: (_, row) => {
        return (
          <Typography>
            {row?.admissionSubValue?.fullName
              ? row?.admissionSubValue?.fullName
              : '-'}
          </Typography>
        )
      },
    },
    {
      title: 'Route Name',
      dataIndex: 'registration.route.routeName',
      key: 'routeName',
    },
    {
      title: 'Boarding Point',
      dataIndex: 'registration.boardingPoint.boardingPoint',
      key: 'boardingPoint',
    },
    {
      title: 'Total Fee',
      dataIndex: 'payableAmount',
      key: 'payableAmount',
    },
    {
      title: 'Paid',
      dataIndex: 'debit',
      key: 'debit',
    },
    {
      title: 'Due',
      dataIndex: 'totalDue',
      key: 'totalDue',
    },
    {
      title: 'Year',
      dataIndex: 'registration.year',
      key: 'year',
      render: (_, row) => (
        <Typography>
          {row?.registration?.year ? row?.registration?.year : '-'}
        </Typography>
      ),
    },
    {
      title: 'Batch',
      dataIndex: 'admissionSubValue.batch',
      key: 'batch',
    },
    {
      title: 'Roll No.',
      dataIndex: 'admissionSubValue.rollNo',
      key: 'rollNo',
      render: (_, row) => (
        <Typography>
          {row?.admissionSubValue?.rollNo
            ? row?.admissionSubValue?.rollNo
            : '-'}
        </Typography>
      ),
    },
  ]

  return (
    <>
      <StyledBox>
        <StyledBoxInner>
          <SingleSelect
            label={'Transport Batch'}
            name={'batch'}
            containerProps={{ width: '200px' }}
            options={batchOptions.map((item, index) => ({
              label: item,
              value: item,
            }))}
            value={state.filters?.transportBatch}
            onChange={(e: any) => {
              setState({
                ...state,
                filters: {
                  ...state.filters,
                  transportBatch: e.target.value,
                },
              })
            }}
          />
          <SearchBar
            onSearch={(v) => {
              setState({
                ...state,
                filters: {
                  ...state.filters,
                  search: v,
                },
              })
            }}
            textFieldProps={{
              defaultValue: state.filters.search,
              containerProps: { width: '400px' },
              label: 'Search by Name / Roll No /Admission Id',
            }}
          />
          <DrawerButton
            anchor={({ open }) => (
              <FilterButton
                onClick={open}
                filtersApplied={state.filtersApplied}
              />
            )}
            content={({ close }) => (
              <ReportFilters close={close} setState={setState} state={state} />
            )}
            title="Apply Filters"
          />
        </StyledBoxInner>
        <Button
          variant="outlined"
          onClick={() => feeDuesExport(state?.filters)}
        >
          Export
        </Button>
      </StyledBox>
      <Table
        key={'feeDueReport'}
        rowKey={'id'}
        dataSource={data?.feeDue ?? []}
        columns={columns}
        loading={isLoading}
        pagination={{
          page: state.filters.skip / state.filters.limit,
          limit: state.filters.limit,
          onChange: handlePagination,
          onChangeLimit: handlePageLimit,
          totalCount: data?.count,
        }}
      />
    </>
  )
}

export default FeeDuesReport
